var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"allmain"},[_c('div',{staticClass:"header"},[_vm._m(0),_c('div',{staticClass:"header_center"},[_c('div',{on:{"click":_vm.gohome}},[_vm._v("首页")]),_c('div',{staticStyle:{"color":"#2558e4"},on:{"click":_vm.goproductIntroduction}},[_vm._v(" 产品介绍 ")]),_c('div',{on:{"click":_vm.gocompanyprofile}},[_vm._v("公司简介")])]),_vm._m(1)]),_vm._m(2),_c('div',{staticClass:"main_title"},[_c('div',{staticClass:"magin_titleData"},[_vm._v("分群客群数据服务")]),_c('div',{staticClass:"main_cardList"},_vm._l((_vm.cardData),function(item,index){return _c('el-card',{key:index,staticClass:"main_card"},[_c('div',[_c('img',{attrs:{"src":require('@/assets/' + item.img)}})]),_c('div',[_vm._v(_vm._s(item.name))]),_c('div',[_vm._v(_vm._s(item.value))])])}),1)]),_c('div',{staticClass:"main_case"},[_c('div',{staticClass:"main_case_bg"},[_c('div',{staticClass:"magin_titleData"},[_vm._v("客群+")]),_c('div',{staticClass:"main_cardList"},_vm._l((_vm.incrementCardData),function(item,index){return _c('el-card',{key:index,staticClass:"main_card"},[_c('div',[_c('img',{attrs:{"src":require('@/assets/' + item.img)}})]),_c('div',[_vm._v(_vm._s(item.name))]),_c('div',[_vm._v(_vm._s(item.value))])])}),1)])]),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header_logo"},[_c('img',{attrs:{"src":require("../../assets/logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header_right"},[_c('div',[_c('span',[_vm._v("联系我们")])]),_c('div',[_c('img',{attrs:{"src":require("../../assets/wechatcode.png")}})]),_c('div',[_c('div',{staticClass:"header_right_wechat"},[_c('img',{attrs:{"src":require("../../assets/wechat.png")}}),_c('div',[_vm._v("fenqunshuju")])]),_c('div',{staticClass:"header_right_wechat"},[_c('img',{attrs:{"src":require("../../assets/phone.png")}}),_c('div',[_vm._v("19967004532")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('img',{attrs:{"src":require("../../assets/productIntroduction_1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_bottom"},[_c('div',{staticClass:"main_bottom_bg"},[_c('div',{staticClass:"main_bottom_apply"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/apply.png")}})]),_c('div',[_c('span',[_vm._v("填写入驻申请，专人联系为您服务！")])]),_c('div',[_c('img',{attrs:{"src":require("../../assets/code.png")}})]),_c('div')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer_logo"},[_c('img',{attrs:{"src":require("../../assets/fqsj_2.png")}})]),_c('div',{staticClass:"footer_right"},[_c('div',[_c('span',[_vm._v("联系我们")])]),_c('div',[_c('img',{attrs:{"src":require("../../assets/wechatcode.png")}})]),_c('div',[_c('div',{staticClass:"header_right_wechat"},[_c('img',{attrs:{"src":require("../../assets/wechat_on.png")}}),_c('div',[_vm._v("fenqunshuju")])]),_c('div',{staticClass:"header_right_wechat"},[_c('img',{attrs:{"src":require("../../assets/phone_on.png")}}),_c('div',[_vm._v("19967004532")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"record"},[_c('div',[_c('a',{attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_vm._v("湘ICP备2023024436号")])]),_c('div',[_vm._v("联系地址：开福区秀峰街道秀峰商贸城12栋2号101")])])
}]

export { render, staticRenderFns }