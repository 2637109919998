<template>
  <div class="allmain">
    <div class="header">
      <div class="header_logo">
        <img src="../../assets/logo.png" />
      </div>
      <div class="header_center">
        <div @click="gohome">首页</div>
        <div style="color: #2558e4" @click="goproductIntroduction">
          产品介绍
        </div>
        <div @click="gocompanyprofile">公司简介</div>
        <!-- <div @click="gopayorder">立即购买</div> -->
      </div>
      <div class="header_right">
        <div>
          <span>联系我们</span>
        </div>
        <div>
          <img src="../../assets/wechatcode.png" />
        </div>
        <div>
          <div class="header_right_wechat">
            <img src="../../assets/wechat.png" />
            <div>fenqunshuju</div>
          </div>
          <div class="header_right_wechat">
            <img src="../../assets/phone.png" />
            <div>19967004532</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <img src="../../assets/productIntroduction_1.png" />
    </div>
    <div class="main_title">
      <div class="magin_titleData">分群客群数据服务</div>
      <div class="main_cardList">
        <el-card
          class="main_card"
          v-for="(item, index) in cardData"
          :key="index"
        >
        <div>
              <img :src="require('@/assets/' + item.img)" />
            </div>
          <div>{{ item.name }}</div>
          <div>{{ item.value }}</div>
        </el-card>
      </div>
    </div>
    <div class="main_case">
      <div class="main_case_bg">
        <div class="magin_titleData">客群+</div>
        <div class="main_cardList">
          <el-card
            class="main_card"
            v-for="(item, index) in incrementCardData"
            :key="index"
          >
            <div>
              <img :src="require('@/assets/' + item.img)" />
            </div>
            <div>{{ item.name }}</div>
            <div>{{ item.value }}</div>
          </el-card>
        </div>
      </div>
    </div>

    <div class="main_bottom">
      <div class="main_bottom_bg">
        <div class="main_bottom_apply">
          <div>
            <img src="../../assets/apply.png" />
          </div>
          <div>
            <span>填写入驻申请，专人联系为您服务！</span>
          </div>
          <div>
            <img src="../../assets/code.png" />
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer_logo">
        <img src="../../assets/fqsj_2.png" />
      </div>
      <div class="footer_right">
        <div>
          <span>联系我们</span>
        </div>
        <div>
          <img src="../../assets/wechatcode.png" />
        </div>
        <div>
          <div class="header_right_wechat">
            <img src="../../assets/wechat_on.png" />
            <div>fenqunshuju</div>
          </div>
          <div class="header_right_wechat">
            <img src="../../assets/phone_on.png" />
            <div>19967004532</div>
          </div>
        </div>
      </div>
    </div>
    <div class="record">
      <div>
        <a href="https://beian.miit.gov.cn" target="_blank">湘ICP备2023024436号</a>
      </div>
      <div>联系地址：开福区秀峰街道秀峰商贸城12栋2号101</div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      cardData: [
        {
          name: "APP移动端",
          img: "productIntroduction_2.png",
          value: "移动设备实时监测客流数据，分析业绩结构！",
        },
        {
          name: "WEB端管理系统",
          img: "productIntroduction_9.png",
          value: "门店客群数据分析平台，指导经营决策！",
        },
        {
          name: "数字可视化大屏",
          img: "productIntroduction_3.png",
          value: "连锁门店BI,数字可视化大屏分析数据。",
        },
        {
          name: "门店客群诊断报告",
          img: "productIntroduction_4.png",
          value: "诊断门店的客群数据，以及客群的过店、进店、成交率的转化数据。",
        },
      ],
      incrementCardData: [
        {
          name: "时段 ＋客群",
          img: "productIntroduction_5.png",
          value: "通过采集门店客群数据，分析各个天气，对不同客群趋势的影响",
        },
        {
          name: "天气＋客群",
          img: "productIntroduction_6.png",
          value: "根据采集数据，分析主力客群，针对主力客群优化商品营销策略。",
        },
        {
          name: "营销＋客群",
          img: "productIntroduction_7.png",
          value:
            "通过采集门店客群数据，分析营销效率，以及客群变化，与非营销日的变化趋势。",
        },
        {
          name: "事件＋客群",
          img: "productIntroduction_8.png",
          value:
            "通过采集门店客群数据，分析各种事件对客流群体结构的影响和变化趋势。",
        },
      ],
    };
  },
  methods: {
    goDetail() {
      // 在 Vue 实例或组件中调用
      this.$router.push({ name: "caseDetail" });
    },
    goApply() {
      this.$router.push({ name: "applicationSettlement" });
    },
    gohome() {
      this.$router.push({ name: "home" });
    },
    goproductIntroduction() {
      this.$router.push({ name: "productIntroduction" });
    },
    gocompanyprofile() {
      this.$router.push({ name: "companyprofile" });
    },
    gopayorder() {
      this.$router.push({ name: "payOrder" });
    },
  },
};
</script>
  
  <style scoped>
.allmain {
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.header {
  height: 100px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header_right {
  display: flex;
  align-items: center;
}
.header_right div {
  margin: 0 10px;
}
.header_right_wechat {
  padding: 5px 0px;
  display: flex;
  align-items: center;
}
.header_center {
  width: 50%;
  display: flex;
}
.header_center div {
  margin: 0px 50px;
}
.main img {
  width: 100%;
}
.main_title {
  text-align: center;
  margin: 120px 15%;
}
.main_cardList {
  display: flex;
}
.main_cardList img{
  width: 100%;
  height: 100%;
}
.main_card {
  width: 25%;
  padding: 70px 15px 20px 15px;
  margin: 10px;
  text-align: center;
}
.main_card div:nth-child(1){
  width: 100%;
  height: 150px;
}
.main_card div:nth-child(2) {
  font-size: 20px;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 20px;
}

.main_card div:nth-child(3) {
  font-size: 14px;
  color: #777777;
  text-align: left;
}
.magin_titleData {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 80px;
}
.main_case {
  width: 100%;
  background: url("../../assets/case_1.png") no-repeat;
  background-size: 100% 100%;
}
.main_case_bg {
  text-align: center;
  padding: 50px 15%;
}
.main_bottom {
  width: 100%;
  background: url("../../assets/FQSJ_logo.png") no-repeat;
  background-size: 100% 100%;
}
.main_bottom_bg {
  text-align: center;
  height: 320px;
}
.main_bottom_apply div:nth-child(1) {
  padding: 80px 0px 20px 0px;
}
.main_bottom_apply div:nth-child(2) {
  color: #777777;
  font-size: 14px;
  padding-bottom: 20px;
}
.main_bottom_apply .el-button {
  width: 160px;
  height: 42px;
  color: white;
  background-color: #2558e4;
  border-radius: 10px;
}
.footer {
  background-color: #111111;
  color: white;
  height: 100px;
  padding: 0px 12%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_right {
  display: flex;
  align-items: center;
}
.footer_right div {
  margin: 0 10px;
}
.record {
  height: 80px;
  color: white;
  padding: 0 10%;
  background-color: #3d3d3d;
  width: 80%;
  line-height: 40px;
}

.main_card:nth-child(1) img{
  width: 60%;
}
.record a{
  color: white;
  text-decoration: none;
}
</style>
  